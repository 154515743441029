import styled from '@emotion/styled'
import React from 'react'
import { mq } from './../../../utility/break'
import LogoSvg from './logo-dark.svg'

interface LogoProps {
  uri: string
}

export const Logo: React.FC<LogoProps> = ({
  uri = 'http://www.siemens.com',
}) => (
  <LogoHolder href={uri} target="_blank">
    <Brand src={LogoSvg} alt="Siemens" />
  </LogoHolder>
)

const LogoHolder = styled.a`
  ${mq[3]}: {
    margin-top: 40px;
  }
  grid-column: 3/7;
  z-index: 1002;
`
// Double size/scale 50% cause safari rendering bug at svg
const Brand = styled.img({
  width: '240px',
  transform: 'scale(0.5)',
  transformOrigin: 'left top',
})
