import { Theme } from '@emotion/react' 
import { defaultTheme } from 'cuenect-web-core'
import { rgba } from 'emotion-rgba'

import {
  SieFullscreenEnter,
  SieFullscreenExit,
  SiePause,
  SiePlay,
  SieSettings,
  SieVolumeDown,
  SieVolumeMute,
  SieVolumeUp,
} from '../../components'

const WHITE = '#fff'
const BLACK = '#000'

// Corporate Colors
const PETROL = '#009999'

// Primary Colors
const STONE_DARKER = '#2d373c'
const STONE_DARK = '#3c464b'
const STONE = '#cdd9e1'
const STONE_LIGHT = '#879baa'
const STONE_LIGHT_4 = '#dfe6ed'

const STONE_LIGHT_35 = '#becdd7'
const STONE_LIGHTEST = '#ebf0f5'

const SAND_DARK = '#73645a'
const SAND_LIGHT = '#aaaa96'
const SAND_LIGHT_35 = '#d7d7cd'
const SAND_LIGHT_35_2 = '#dfe6ed'

const SNOW = '#ffffff'

// Accent Colors
const TEAL_DARK = '#00646e'
const TEAL_LIGHT = '#41aaaa'

const BLUE_DARK = '#005f87'
const BLUE_LIGHT = '#50bed7'
const BG_SUBMENU = '#00183B'

const BTN_GRAY = '#737389'
const BTN_BG_DISABLED = '#00183B'

const CONTAINER_DIM = '#000000'

const YELLOW_DARK = '#eb780a'
const YELLOW_LIGHT = '#ffb900'
const YELLOW_LIGHT_1 = '#faa50a'

const RED_DARK = '#641946'
const RED_LIGHT = '#af235f'

const GREEN_LIGHT = '#647d2d'
const GREEN_DARK = '#aab414'
const GREEN_DARKER = '#00640a'

const BUTTON_ALT = '#2387AA'
const LIGHT_GREY = '#E7E7E7'
const LIGHT_GREY_D9 = '#D9D9D9'

const TABLE_BORDER_LINE = '#4C4C68'

const BOLD_GREEN = '#00FFB9'

const DEEP_BLUE = '#000028'
const LIGHT_BLUE = '#001c31'
const GREEN = '#00af8e'
const SOFT_GREEN = '#00d7a0'
const LINK_DEACTIVE = '#8c8c8c'
const INPUT_BACKGROUND = '#00CCCC'
const ERROR = '#FF5454'

const DEFAULT_GRADIENT = `linear-gradient(to right, #50bebe 0%,#009999 50%,#0099cb 83%,#0099b0 100%)`
const BUTTON_GRADIENT = `linear-gradient(to right, #00FFB9 0%, #00E6DC 100%)`
const BLOCK_GRADIENT = `linear-gradient(to left, #00FFB9 0%, #00E6DC 100%)`
const MOBILE_MENU_GRADIENT = `linear-gradient(180deg, ${DEEP_BLUE} 0%, #001034 100px, #001034 100%)`
const LINE_DECORATOR = 'linear-gradient(180deg,#00ffb9,#00e6dc);'

const spacer = 0.25
const SPACING = [
  0, // 0
  `${spacer * 0.25}rem`, // 1
  `${spacer * 0.5}rem`, // 2
  `${spacer}rem`, // 3
  `${spacer * 1.5}rem`, // 4
  `${spacer * 3}rem`, // 5
]


// Override default Theme
export const siemensTheme: Theme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    text: SNOW,
    primary: STONE_LIGHT,
    tertiary: PETROL,
    light: LIGHT_GREY,
    odd: STONE_LIGHTEST,
    even: STONE_LIGHT_35,
    footer: DEEP_BLUE,
    contentLight: STONE_LIGHTEST,
    link: SNOW,
    linkHover: BOLD_GREEN,
    linkDeactive: LINK_DEACTIVE,
    linkOnDark: INPUT_BACKGROUND,
    lightBlue: LIGHT_BLUE,
    inputBackground: rgba(INPUT_BACKGROUND, 0.08),
    inputText: WHITE,
    inputBorder: '#B3B3B3',
    inputBackgroundHover: rgba(INPUT_BACKGROUND, 0.12),
    inputBackgroundActive: rgba(INPUT_BACKGROUND, 0.2),
    inputBorderHover: '#2EE6E2',
    bodyBackground: DEEP_BLUE,
    bgSubMenu: BG_SUBMENU,
    tableBackground: SNOW,
    tableBorder: LIGHT_GREY_D9,
    tableBorderLine: TABLE_BORDER_LINE,
    tableColor: SNOW,
    dim: CONTAINER_DIM,
    bgColor: DEEP_BLUE,

    buttonText: DEEP_BLUE,
    buttonTextDisabled: BTN_GRAY,
    buttonActiveText: SNOW,
    buttonBackground: BUTTON_GRADIENT,
    buttonBackgroundDisabled: BTN_BG_DISABLED,
    buttonActiveBackground: BOLD_GREEN,
    buttonSecondaryBackground: GREEN,
    buttonSecondaryActiveBackground: BLUE_LIGHT,
    buttonTertiaryBackground: BUTTON_ALT,
    buttonTertiaryActiveBackground: BLUE_LIGHT,

    blockGadient: BLOCK_GRADIENT,
    lineDecorator: LINE_DECORATOR,
    error: ERROR,
    videoControlsBg: rgba(DEEP_BLUE, 0.7),
  },
  fonts: {
    ...defaultTheme.fonts,
    fontSize: '18px',
    fontFamily: 'Siemens Sans Roman',
    fontFamilyBlack: 'Siemens Sans Black',
    headingSizes: {
      ...defaultTheme.fonts.headingSizes,
      h1: '60px',
      h2: '40px',
      h3: '36px',
      h4: '32px',
      h5: '24px',
      h6: '18px',
    },
    headingSizesMobile: {
      ...defaultTheme.fonts.headingSizes,
      h1: '40px',
      h2: '32px',
      h3: '30px',
      h4: '26px',
      h5: '24px',
      h6: '16px',
    },
    text: {
      sizes: {
        14: '14px',
        16: '16px',
        17: '17px',
        18: '18px',
        20: '20px',
        24: '24px',
        26: '26px',
        30: '30px',
      },
      lineHeight: '26px',
    },
  },
  icons: {
    style: {
      color: WHITE,
      size: '1.5rem',
    },
    player: {
      VolumeDown: SieVolumeDown,
      VolumeMute: SieVolumeMute,
      VolumeUp: SieVolumeUp,
      FullscreenEnter: SieFullscreenEnter,
      FullscreenExit: SieFullscreenExit,
      Settings: SieSettings,
      Pause: SiePause,
      Play: SiePlay,
    },
  },
  margin: {
    t: {
      '0': { marginTop: SPACING[0] },
      '1': { marginTop: SPACING[1] },
      '2': { marginTop: SPACING[2] },
      '3': { marginTop: SPACING[3] },
      '4': { marginTop: SPACING[4] },
      '5': { marginTop: SPACING[5] },
    },
    r: {
      '0': { marginRight: SPACING[0] },
      '1': { marginRight: SPACING[1] },
      '2': { marginRight: SPACING[2] },
      '3': { marginRight: SPACING[3] },
      '4': { marginRight: SPACING[4] },
      '5': { marginRight: SPACING[5] },
    },
    b: {
      '0': { marginBottom: SPACING[0] },
      '1': { marginBottom: SPACING[1] },
      '2': { marginBottom: SPACING[2] },
      '3': { marginBottom: SPACING[3] },
      '4': { marginBottom: SPACING[4] },
      '5': { marginBottom: SPACING[5] },
    },
    l: {
      '0': { marginLeft: SPACING[0] },
      '1': { marginLeft: SPACING[1] },
      '2': { marginLeft: SPACING[2] },
      '3': { marginLeft: SPACING[3] },
      '4': { marginLeft: SPACING[4] },
      '5': { marginLeft: SPACING[5] },
    },
  },
  dimensions: {
    maxWidth: '1356px', // 1170px
    maxContentWidth: '1024px',
  },
  grid: {
    columns: 36,
  },
}
