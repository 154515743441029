import { Global } from '@emotion/core'
import { Theme, ThemeProvider as CustomThemeProvider } from '@emotion/react'
import styled from '@emotion/styled'
import {
  AtomsProvider,
  DebugMenu,
  LocalStorageUtil,
  MOCK_TIME,
  ThemeProvider,
  TimeUtil,
  TranslationProvider,
  useFeatureFlags,
  withDebugMenuControls,
} from 'cuenect-web-core'
import dayjs from 'dayjs'
import locale_de from 'dayjs/locale/de'
import RelativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { rgba } from 'emotion-rgba'
import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { I18nUtil, withTrans } from '../../../i18n'
import { useAuthentication } from '../../../utility/authentication'
import { LoadingIndicator } from '../../atoms/loadingIndicator'
import { Footer } from '../footer'
import { Header } from '../header'
import {
  AgendaCta,
  Button,
  CookieConsent,
  DraggableSlider,
  FakeFullscreen,
  LoadingSpinner,
  MobileVodVideo,
  Modal,
  SlideIn,
} from './../../'
import {
  AppointmentService,
  ProgramEvent,
  ProgramService,
} from './../../../api'
import { isPostEvent, languages } from './../../../config'
import './../../../themes/siemens/global.css'
import { siemensTheme } from './../../../themes/siemens/siemensTheme'
import {
  AnalyticsTransformer,
  LoadAnalytics,
  mq,
  useFullscreenContext,
  useModalContext,
  useReservationContext,
  useVodContext,
} from './../../../utility'
import { useAuth0 } from '@auth0/auth0-react'
import useAccessToken from './../../../utility/useAccessToken'

dayjs.extend(utc)
dayjs.extend(RelativeTime)

export interface InnerContainerParams {
  isTop?: boolean
}

export interface PageParams extends InnerContainerParams {
  pageContext: {
    lang: string
    item?: string
    program?: string
    hidePostEvent?: boolean
  }
  isTop?: boolean
}

export interface PageFrameProps extends PageParams {
  pageName: string
  hideMenu?: boolean
  theme?: Theme
}

export const PageFrame: React.FC<PageFrameProps> = ({
  children,
  pageContext: { lang = 'en', hidePostEvent = false },
  isTop = false,
  pageName,
  hideMenu = false,
}) => {
  TimeUtil.setLocale(lang === 'de' ? locale_de : 'en')

  const DebugMenuWithControls = () => withDebugMenuControls(DebugMenu)
  const [modalState, setModalState] = React.useState({ showDebugMenu: false })
  const [participations, setParticipations] = React.useState<
    string[] | undefined
  >()
  const [hasAppointment, setHasAppointment] = React.useState<boolean>(false)
  const byPassAuth: boolean =
    pageName === 'support' ||
    pageName === 'forbidden' ||
    pageName === 'cookies' ||
    pageName === 'debug' ||
    pageName === 'salesform-7hs5Hz3as' ||
    pageName === 'dialog-appointment'

  const { isLoading, error, isAuthenticated, loginWithRedirect, user } = useAuth0()
  const accessToken = useAccessToken()

  console.log("useAuth0", user)

  if (error) {
    console.log('error', error)
    return <div>Oops... {error.message}</div>
  }
  /*
  if (isLoading) {
    return <div>Loading</div>;
  } */

  if (!isLoading && !accessToken && !isAuthenticated) {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('hash', window.location.hash)
    }
    loginWithRedirect()
  }
  const {
    state: { isFullscreen },
  } = useFullscreenContext()

  // CHECK MOCK DATE URL
  const dateString = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  ).get('mockDate')

  if (dateString) {
    const mockDate = TimeUtil.getUtc(dateString)

    const diff = dayjs.utc().diff(mockDate, 'ms')
    LocalStorageUtil.remove(MOCK_TIME)
    LocalStorageUtil.saveData(MOCK_TIME, { mockDiffMs: diff })
  }

  useEffect(() => {
    function handlekeydownEvent(event: KeyboardEvent) {
      const { code, altKey } = event
      if (code === 'KeyD' && altKey) {
        setModalState({ showDebugMenu: true })
      }
      if (code === 'KeyT' && altKey) {
        setModalState({ showDebugMenu: true })
      }
    }
    document.addEventListener('keyup', handlekeydownEvent)

    return () => {
      document.removeEventListener('keyup', handlekeydownEvent)
    }
  }, [])

  useEffect(() => {}, [isAuthenticated, isLoading])

  const currentheme = siemensTheme

  return (
    <>
      <Helmet>
        <title>Siemens | HM21 Showroom</title>
        <meta name="description" content="" />
      </Helmet>
      <LoadAnalytics />

      <TranslationProvider
        translations={I18nUtil.flattenLocales(I18nUtil.getLocale(lang))}
      >
        <ThemeProvider theme={siemensTheme}>
          <CustomThemeProvider theme={siemensTheme}>
            <AtomsProvider
              customAtoms={{
                AgendaCta,
                Modal,
                Button,
                LoadingSpinner,
                DraggableSlider,
                FakeFullscreen,
              }}
            >
              {isLoading && (
                <Loading>
                  <LoadingIndicator />
                </Loading>
              )}
              <Modal
                show={modalState.showDebugMenu}
                className="debugModal"
                onClickOutside={() => setModalState({ showDebugMenu: false })}
                style={{ zIndex: 3000 }}
              >
                <DebugMenuWithControls></DebugMenuWithControls>
              </Modal>

              <Header
                hideMenu={!isAuthenticated || hideMenu}
                isTop={isTop}
                hasParticipation={
                  (participations && participations?.length > 0) ||
                  hasAppointment
                }
              />
              {!isLoading && (
                <InnerContainer isTop={isTop}>{children}</InnerContainer>
              )}
              <SlideIn />
              <CookieConsent page={pageName} lang={lang} />
              <Footer currentLanguage={lang} />
            </AtomsProvider>
          </CustomThemeProvider>
        </ThemeProvider>
      </TranslationProvider>
      <Global
        styles={{
          html: {
            height: '100%',
            overflowY: isFullscreen ? 'hidden' : 'inherit',
            position: isFullscreen ? 'fixed' : 'inherit',
            overscrollBehavior: 'none',

            backgroundColor: currentheme.colors.bodyBackground,
            fontFamily: currentheme.fonts.fontFamily,
            color: currentheme.colors.text,
            body: {
              borderTop:
                LocalStorageUtil.getString('mockTime') ||
                LocalStorageUtil.getString('mockTimezone')
                  ? `10px solid ${currentheme.colors.error}`
                  : 'none',
            },
            '.customTooltip': {
              background: currentheme.colors.buttonSecondaryBackground,
              maxWidth: '200px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.buttonSecondaryBackground} !important`,
              },
            },
            '.showroomTooltip': {
              background: rgba(currentheme.colors.lightBlue, 1),
              maxWidth: '200px',
              borderRadius: 0,
              fontSize: '12px',
              zIndex: 999999,
              '&:after': {
                borderTopColor: `${currentheme.colors.lightBlue} !important`,
              },
            },
            '.debugModal > div': {
              background: currentheme.colors.bodyBackground,
              input: {
                color: '#000',
              },
            },
            '.crisp-client': {
              display: pageName === 'support' ? 'block' : 'none',
            },
          },
        }}
      ></Global>
    </>
  )
}
export default withTrans(PageFrame)

const InnerContainer = styled.div<InnerContainerParams>(
  ({ theme: { grid }, isTop }) => `
  padding-top:50px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 50000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
  min-height: calc(100vh - 330px);
  ${isTop && 'margin-top:-45px; min-height:100vh; '}

  ${mq[3]} {
    padding-top:0px;

    min-height: calc(100vh - 370px);
    ${isTop && 'margin-top:-210px; '}
  }


`
)

const Loading = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  height:100vh;
  left:0;top:0;
  backdrop-filter: blur(5px);
  display:flex;
  align-items:center;justify-content:center;
  background:${rgba(colors.bodyBackground, 0.5)};
`
)
const DebugBarTop = styled.div(
  ({ theme: { colors, fonts } }) => `
  position:fixed;
  z-index:1003;
  width:100vw;
  padding:5px;
  top:0;
  display:flex;
  align-items:center;
  justify-content:center;
  background:${rgba(colors.error, 0.5)};
`
)
