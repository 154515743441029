var hash = require('hash.js')
const useAccessToken = (container = null) => {
  const urlHash =
    'c4faa28b098592b9efef72dcb0cbfa59b4e579b8311a004633b9a48960696265'
  if (typeof window === 'undefined') {
    return false
  }
  const token = new URLSearchParams(window.location.search).get('token')
  const local = window.localStorage.getItem('token')

  const accessToken =
    hash
      .sha256()
      .update(token || local)
      .digest('hex') === urlHash

  if (accessToken) window.localStorage.setItem('token', token || local)

  return accessToken
}

export default useAccessToken
